body {

  .table {

    &__top {
      display: none;
    }

    table {

      td {
        @include td-width(100, 200);

        /* stylelint-disable */
        &._underline {
          text-decoration: underline;
        }

        &._checkbox {
          @include td-width(36, 36);
        }

        &._audience-name {
          @include td-width(300, 300);
        }
        /* stylelint-enable */
      }
    }
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/button';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  width: 100%;
  border: .0625rem dashed $table-row-hover;
}

.file {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.fileInput {
  display: none;
}

.dropable {
  border-color: #6075ff;
}

.browseButton {
  margin-right: 1rem;

  &:global(.btn-square) {

    svg {
      width: 1rem;
      height: auto;
    }
  }
}

.sample {
  @extend %button;
  @extend %text;
  text-decoration: underline;
  cursor: pointer;
}

.text {
  margin-bottom: .375rem;
  @extend %text;
  color: $silver-chalice;
  text-align: center;
}

.error {
  @include f1-400();
  font-size: .75rem;
  line-height: 14/12+em;
  color: $persimmon;
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 0 11/16+rem;
}

.item {
  flex: 0 1 33.333%;
  min-width: 0;
  padding: 5/16+rem;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem .25rem;
  min-height: 96/16+rem;
  cursor: pointer;
  transition: .25s;
  text-decoration: none;

  &:hover {
    background: $titan-white;
  }

  &Title {
    @include f1-400();
    transition: color .25s;
    font-size: 14/16+rem;
    line-height: 17/14+em;
    color: $silver-chalice;
    text-align: center;
  }

  &:hover & {

    &Title {
      color: $conflower-blue;
    }
  }

  &Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8/16+rem;
    height: 20/16+rem;
    width: 20/16+rem;

    svg {
      width: 100%;
      height: auto;
    }
  }
}


@import './mixins';

body {

  .text-field {
    z-index: 1;

    &__label {
      display: flex;
      align-items: center;
      white-space: nowrap;

      .tooltip {
        display: flex;

        path {
          fill: $gray;
        }
      }
    }
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/button';

:global(.dialog) {

  &:local {

    &.dialog {
      position: relative;
      padding: 2rem;
      min-width: 260/16+rem;
      height: auto;
      min-height: 0;

      :global {

        .dialog {

          &__content {
            padding: 0;
          }
        }
      }

      .row {

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      .p {
        margin-top: .5rem;
        color: $gray;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;

        > * {

          &:not(:last-child) {
            margin-right: .5rem;
          }
        }
      }
    }
  }
}

.close:global(.btn-close) {
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.track {
  position: relative;
  height: 36/16+rem;
  width: 100%;
  border-radius: 0;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 5/16+rem;
    width: 100%;
    background: $melrose;
    pointer-events: auto;
  }
}

.slider {
  z-index: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36/16+rem;
  height: 36/16+rem;
  border-radius: 50%;
  border: 1/16+rem solid $melrose;
  background: #fff;
  box-shadow: 0 2/16+rem 4/16+rem 0 rgba($silver-chalice, .5);
  transition: transform .25s;
  pointer-events: auto;
}

.value {
  @include f1-400();
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14/16+rem;
  line-height: 1.15;
  color: #000;
  letter-spacing: -.01em;
  text-align: center;
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.selected {
  @include f1-400();
  display: flex;
  align-items: center;
  margin-right: 12/16+rem;
  font-size: 17/16+rem;
  line-height: 21/17+em;
  color: #656565;
}

.remove:global(.btn-close) {
  margin-right: 6/16+rem;
  width: 18/16+rem;
  height: 18/16+rem;
  border-radius: 50%;
  background: $alto;

  &:before,
  &:after {
    width: 10/16+rem;
    height: 1/16+rem;
    background: #fff;
    border-radius: 2/16+rem;
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/button';

.steps {
  display: flex;
  margin: 0 -.125rem 6/16+rem;
}

.step {
  flex: 1 1 auto;
  margin: 0 .125rem;
  height: .25rem;
  border-radius: .125rem;
  background: $table-row-hover;
}

.status {
  color: $dove-gray;

  &Number {
    color: $cornflower-blue;
  }
}

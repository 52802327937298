@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/button';

.row {

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.checkbox {

  &:not(:last-child) {
    margin-bottom: .25rem;
  }
}

@import '../../scss/variables';
@import '../../scss/mixins';

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:global {

    &:not(._mainpage) {

      :local {

        .header {

          &Wrapper {
            border-bottom: 1/16+rem solid $wild-sand;
          }
        }
      }
    }
  }
}

.container {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.row {

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.header {
  flex: 0 0 auto;

  &Wrapper {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    min-height: 50/16+rem;
  }

  &Search {
    padding: .75rem 2rem 6/16+rem;
  }
}

.icon {
  margin-right: 1rem;
  line-height: 0;
}

.title {
  @include f1-700();
  margin-right: auto;
  font-size: 20/16+rem;
  line-height: 24/20+em;
  color: #000;
}

.creative {
  display: flex;
  align-items: center;
  padding: 10/16+rem 1rem;
  border-bottom: 1/16+rem solid $wild-sand;

  &Icon {
    flex: 0 0 26/16+rem;
    margin-right: 1rem;
  }

  span {
    @include f1-700();
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.main {
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 1.5rem;
}

.footer {
  position: relative;
  display: flex;
  justify-content: space-between;

  &Btn {

    &:global(.btn-square) {
      padding-left: 1rem;
      padding-right: 1rem;
      min-width: 0;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.create {

  &:global {

    &.btn-square {
      min-width: 90/16+rem;
      padding: 0 1em;
    }
  }
}

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 2rem;

  &:global {

    &._right-sidebar {
      padding: 1rem 94/16+rem 1rem 2rem;
    }
  }
}

@import '~factor/src/scss/variables';
@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/button';

tbody {

  .tdExpand {

    :global {

      .td-content {
        padding: 0;
        justify-content: center;
        align-items: stretch;

        &-inner {
          display: flex;
          align-items: stretch;
          width: 100%;
        }
      }
    }
  }
}

.expand {
  @extend %button;
  width: 100%;
  cursor: pointer;
  font-family: Helvetica;
  font-size: 26/16+rem;
  color: $silver-chalice;
}

.actions {

  &:global {

    &.btn-round {

      .btn-round {

        &__prefix {
          width: auto;

          svg {
            width: 16/16+rem;
            height: auto;
          }

          path {

            &:first-child {
              fill: $conflower-blue;
            }
          }
        }
      }
    }
  }
}

.select {

  &:global {

    &.select {
      width: 256/16+rem;
    }
  }
}

@import '../../scss/variables';
@import '~factor/src/scss/mixins';
@import '~factor/src/scss/button';

.row {

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.tabs {

  :global {

    .tabs__btn {
      flex: 1 1 50% !important;
      margin: 0 !important;
    }
  }
}

.dateRange {
  margin-bottom: 1rem;
}

.copyCode {
  align-self: center;
  margin-bottom: .5rem;

  path {
    fill: $cornflower-blue;
    transition: fill .25s;
  }

  &:hover {

    path {
      fill: $indigo;
    }
  }
}

.urls {
  margin-bottom: 1rem;
}

.url {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &Status {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.success {

      &:before {
        content: '';
        width: 6/16+rem;
        height: 15/16+rem;
        border-right: .125rem solid $apple;
        border-bottom: .125rem solid $apple;
        transform: rotate(45deg);
      }
    }

    &.error {

      &:before {
        content: '';
        width: 6/16+rem;
        height: 6/16+rem;
        border-radius: 50%;
        background: $stiletto;
      }
    }
  }

  &TextField {
    flex: 1 1 auto;
  }

  &Remove {

    &:global(.btn-close) {
      margin: 0 .5rem;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.btn1 {
  padding: 0 1.5rem !important;

  :global(.btn-round__prefix) {
    left: 6/16+rem;
  }
}

.btn2 {
  padding: 0 1rem !important;
}

@import './variables';
@import '~factor/src/scss/mixins';

// Placeholder text
@mixin placeholder($color, $opacity) {
  // Firefox
  /* stylelint-disable */
  &::-moz-placeholder {
    color: $color;
    opacity: $opacity; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
  // Internet Explorer 10+
  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
  // Safari and Chrome
  /* stylelint-enable */
}

//almost for panda-components table cell width
@mixin flex-width($width) {
  flex: 0 1 $width/16+rem;
  min-width: $width/16+rem;
  max-width: $width/16+rem;
}

//block style
@mixin block() {
  border-radius: 9/16+rem;
  border: 1/16+rem solid $alto-1;
  background: #fff;
  box-shadow: 0 0 .75rem 0 rgba($mercury, .5);
}

// TABLE CELL WIDTH
@mixin td-width($min, $max) {
  min-width: $min/16+rem;
  width: $max/16+rem;
  max-width: $max/16+rem;
}
